/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import AOS from 'aos';
import 'aos/dist/aos.css';
import './src/disable-inspect';

export const onInitialClientRender = () => {
    AOS.init({
      duration: 1000, // You can adjust the duration of the animations here
    //   once: true,     // Whether animation should happen only once - while scrolling down
    //   mirror: false,  // Whether elements should animate out while scrolling past them
    });
  };